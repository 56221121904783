import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { useEffect } from "react"
import { useState } from "react"
import BlogCard from "../components/blog/blogCard"
import PageNumber from "../components/blog/pageNumber"
import { Breadcrumbs } from "../components/blog/breadcrumbs"
import Seo from "../components/seo"
import HeroBlog from "../components/blog/hero"
import Navbar from "../components/blog/navbar"
import MissingSearch from "../components/blog/missingSearch"
import SearchBar from "../components/blog/searchBar"
const BlogList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  a {
    text-decoration: none;
    cursor: pointer;
  }
  .row {
    display: flex;
    flex-direction: row;
    /* align-content: center; */
    align-items: center;
    justify-content: space-between;
    margin: 16px 0;
    width: 100%;
    max-width: 1028px;
  }

  .resize {
    width: 100%;
    max-width: 1280px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`

const ArticleContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 5%;
  display: flex;
  flex-wrap: wrap;
  gap: 5% 5%;
  padding: 0 5%;
`
const SearchStyle = styled.div`
  margin-top: 32px;
  width: 80%;
  display: flex;
  justify-content: center;

  border-radius: 0;

  input {
    appearance: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 100%;
    max-width: 480px;
    padding: 4px 8px;
    border: solid rgba(0, 0, 0, 0.25) 1px;
    :focus {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      outline: none;
    }
  }
  .box {
    width: 36px;
    height: 36px;
    border: solid rgba(0, 0, 0, 0.25) 1px;
    cursor: pointer;
  }
`

export default function Blog({ data, location, pageContext }) {
  const posts = data.allPrismicPdfproPost.nodes
  const [checklocation, setCheckLocation] = useState("/")
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")

  let blogNumber = 0

  useEffect(() => {
    let params = new URLSearchParams(document.location.search)
    let page = params.get("page") // is the string "Jonathan"
    console.log("Page: " + page)
    if (page) setPage(page)
  }, [])

  useEffect(() => {
    setCheckLocation(window.location.pathname)
  }, [])

  return (
    <BlogList>
      <Seo title={"Featured"} />
      <HeroBlog />
      <Navbar categories={pageContext.categoryTypes} />
      <Breadcrumbs
        currentPath={["/"].concat(
          location.pathname.split("/").filter(name => name !== "")
        )}
      />
      <div className="resize">
        <SearchBar setPage={setPage} setSearch={setSearch} />

        <ArticleContainer>
          {posts.map((post, i) => {
            if (
              post.data.title.toLowerCase().includes(search.toLowerCase()) ||
              post.data.body.text.toLowerCase().includes(search.toLowerCase())
            ) {
              blogNumber += 1
              return (
                <BlogCard
                  post={post}
                  author={post.data.author.document.data.name}
                  visible={
                    blogNumber - 1 <= 8 * (page - 1) - 1 ||
                    blogNumber - 1 > 8 * page - 1
                      ? "none"
                      : "flex"
                  }
                />
              )
            }
          })}
          {blogNumber == 0 && <MissingSearch />}
        </ArticleContainer>
        <PageNumber
          location={checklocation}
          blogNumber={blogNumber - 1}
          setPage={setPage}
          page={page}
        />
      </div>
    </BlogList>
  )
}

export const query = graphql`
  query BlogFeaturedQuery($featured: Boolean!) {
    allPrismicPdfproPost(
      sort: { fields: data___publish_date, order: DESC }
      filter: { data: { featured: { eq: $featured } } }
    ) {
      nodes {
        data {
          url
          title
          thumbnail {
            gatsbyImageData
          }
          body {
            text
          }
          read_time
          publish_date(formatString: "MMMM D, Y")
          category
          category_color
          fit_image
          description
          featured
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  name
                }
              }
            }
          }
        }
        uid
      }
    }
  }
`
