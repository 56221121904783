import React, { useState } from "react"
import styled from "styled-components"
import Search from "../../icons/blog/noun-search.svg"

const SearchStyle = styled.div`
  margin-top: 32px;
  width: 80%;
  display: flex;
  justify-content: center;

  border-radius: 0;

  input {
    border-radius: 16px 0 0 16px;
    appearance: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-family: DM Sans, Work sans, sans-serif;
    width: 100%;
    max-width: 480px;
    padding: 12px 16px;
    /* border: solid rgba(0, 0, 0, 0.25) 2px; */
    border: none;
    box-shadow: -2px 0px 15px -3px rgba(0, 0, 0, 0.1);
    :focus {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      outline: none;
    }
  }
  .box {
    width: 54px;
    height: 54px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 16px 16px 0;
    box-shadow: 1px 0px 10px -6px #e44331;

    /* border: solid #e44331 2px; */
    cursor: pointer;

    svg {
      fill: #e44331;

      stroke: #e44331;
    }
  }
`
function SearchBar({ setPage, setSearch }) {
  const [input, setInput] = useState("")

  const SearchFunction = () => {
    setPage(1)
    setSearch(input)
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "Searchbar",
      category: "Search",
      action: "User Searched Certain Keyword",
      label: input,
    })
  }

  return (
    <SearchStyle>
      <input
        type="text"
        placeholder="Search here"
        onChange={e => {
          setInput(e.target.value)
        }}
        onKeyPress={e => {
          if (e.key == "Enter") {
            SearchFunction()
          }
        }}
      />
      <div
        className="box"
        onClick={() => {
          SearchFunction()
        }}
      >
        <Search />
      </div>
    </SearchStyle>
  )
}

export default SearchBar
