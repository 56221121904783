import React from "react";
import styled from "styled-components";

const HeroS = styled.div`
  width: 100%;
  background: #fbfbfb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #222222;
`;
const Title = styled.h1`
  font-weight: 500;
  font-size: 52px;
  line-height: 58px;
  font-family: Work Sans;
  font-style: normal;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 20px;
  color: #111111;

  @media (max-width: 375px) {
    font-size: 32px;
    line-height: 36px;
    margin-top: 30px;
    margin-bottom: 16px;
  }
`;
const Paragraph = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  max-width: 500px;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 60px;

  @media (max-width: 375px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px;
  }
`;
function HeroBlog() {
  return (
    <HeroS>
      <Title>The PDF Pro Blog</Title>
      <Paragraph>
        Your source for how-to guides, new releases and everything PDF
      </Paragraph>
    </HeroS>
  );
}

export default HeroBlog;
